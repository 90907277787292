// 
// _footer.scss
// 

.footer {
    bottom: 0;
    padding: 20px calc(#{$grid-gutter-width} / 2);
    position: absolute;
    right: 0;
    // color: $footer-color;
    color: #fff;
    left: 250px;
    height: $footer-height;
    // background-color: $footer-bg;
    background: radial-gradient(circle, rgba(37,160,213,1) 51%, rgba(28,119,185,1) 100%);
    @media (max-width: 991.98px) {
        left: 0;
    }
}

// Enlarge menu
.vertical-collpsed {
    .footer {
        left: $sidebar-collapsed-width;

        @media (max-width: 991.98px) {
            left: 0;
        }
    }
}

body[data-layout="horizontal"] {
    .footer {
        left: 0 !important;
    }
}