// 
// _card.scss
// 

.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  padding: 10px;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}

Button{
  box-shadow: 5px 5px 10px 2px rgba(0,0,0,.2);
}